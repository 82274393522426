import React from 'react'

import './index.scss'

export const GabShareButton = ({ postTitle, postURL }) => {
    return (
            <a
                target="_blank"
                href={`https://gab.com/compose?url=${encodeURIComponent(postURL)}&text=${encodeURIComponent(postTitle + '\n\n')}`}
            >
            <button
                title="Share to Gab"
                id="gab-share-btn"
                class="gab-share-btn"
            >
                Share to Gab
            </button>
            </a>
    );
}

export default GabShareButton
